import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import Nav from "../components/MainNav";
import ConversionTypesAdminTable from "../components/ConversionTypesAdminTable";

const useStyles = makeStyles((theme) => ({
  errorMessage:{
    textAlign: 'center',
    paddingTop: '3rem'
  },
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "85%",
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectedFilter: {
    border: "1px solid #385a64",
    color: "#385a64",
    fontWeight: 600,
  },
  dateFilterContainer: {
    marginTop: "1rem",
    zIndex: "1",
    padding: "1rem",
    background: "white",
    borderRadius: "5px",
  },
}));
const ConversionTypeAdmin = () => {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [conversionTypesData, setConversionTypesData] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [dates, setDates] = useState({});
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUserId().then((id) => setUserId(id));
      setDates(formatStartEndDates());
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    if (!userId || !dates) return;

    let isMounted = true;
    if (isMounted) {
      getConversionTypesData()
        .then((data) => {
          if (!data.length) return;
          setConversionTypesData(data);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            // No conv types found in DB. Backend function does return an error when there is no data. This is a workaround to differenciate fethc errors and no data.
            setConversionTypesData([]);
          } else {
            // There was an error fetching
            setFetchError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => (isMounted = false);
  }, [userId, dates, loading]);

  const getUserId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.sub;
  };

  const formatStartEndDates = () => {
    let date = new Date();
    let start = moment(date).subtract(100, "years").format("YYYY-MM-DD");
    //   .startOf("week").add(1, "day").toDate()
    let end = moment(date).format("YYYY-MM-DD");

    return {
      start: start,
      end: end,
    };
  };

  const getConversionTypesData = async () => {
    const apiName = "ApiGatewayRestApi";
    const path = "/params";
    let qs = getQueryString();
    const response = await API.get(apiName, path, qs);
    return response.data[1];
  };

  const getQueryString = () => {
    return {
      response: true,
      queryStringParameters: {
        userId: userId,
        startDate: dates.start,
        endDate: dates.end,
      },
    };
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {fetchError ? (
          <p className={classes.errorMessage}>An error occured while accessing data. Please refresh the page.</p>
        ) : (
          <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.paddedHeading}>
                  Manage Conversion Types
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div className={classes.titleInfoWrapper}>
                      {loading ? (
                        <div style={{ textAlign: "center", marginTop: "3rem" }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <ConversionTypesAdminTable
                          userId={userId}
                          data={
                            conversionTypesData !== null
                              ? conversionTypesData
                              : []
                          }
                          updateTable={() => setLoading(true)}
                        />
                      )}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
      </main>
    </div>
  );
};

export default ConversionTypeAdmin;
