import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  withStyles,
  Grid,
  SwipeableDrawer,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as Logo } from "../assets/square-logo.svg";

const styleSheet = {
  list: {
    width: 200,
  },
  padding: {
    paddingRight: 30,
    cursor: "pointer",
  },

  sideBarIcon: {
    padding: 0,
    color: "black",
    cursor: "pointer",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "white",
    color: "#141446",
    // boxShadow: "none",
    position: "fixed",
  },
  logo: {
    width: "90px",
    height: "auto",
  },
  toolBar: {
    display: "flex",
  },
  firstLink: {
    marginLeft: "auto",
    padding: "0 20px",
  },
  navItem: {
    padding: "0 20px",
  },
};

class LoggedOutNav extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerActivate: false, drawer: false };
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  componentWillMount() {
    if (window.innerWidth <= 600) {
      this.setState({ drawerActivate: true });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 600) {
        this.setState({ drawerActivate: true });
      } else {
        this.setState({ drawerActivate: false });
      }
    });
  }

  //Small Screens
  createDrawer() {
    return (
      <div>
        <AppBar
          className={this.props.appBar}
          style={{ backgroundColor: "white", boxShadow: "none" }}>
          <Toolbar className={this.props.toolBar}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center">
              <MenuIcon
                className={this.props.classes.sideBarIcon}
                onClick={() => {
                  this.setState({ drawer: true });
                }}
              />
              <Logo style={{ width: "90px", height: "auto" }} />

              <Typography color="inherit" variant="headline"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={this.state.drawer}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          onOpen={() => {
            this.setState({ drawer: true });
          }}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              this.setState({ drawer: false });
            }}
            onKeyDown={() => {
              this.setState({ drawer: false });
            }}>
            <List className={this.props.classes.list}>
              <ListItem
                key={1}
                button
                divider
                component="a"
                href="https://ihc-attribution.com">
                {" "}
                Home{" "}
              </ListItem>
              <ListItem
                key={2}
                button
                divider
                component="a"
                href="https://ihc-attribution.com/ihc-data-driven-attribution-model/">
                {" "}
                IHC attribution model{" "}
              </ListItem>
              <ListItem
                key={3}
                button
                divider
                component="a"
                href="https://ihc-attribution.com/marketing-attribution-api/">
                {" "}
                API{" "}
              </ListItem>
              <ListItem
                key={4}
                button
                divider
                component="a"
                href="https://ihc-attribution.com/attribution-service-contact/">
                Contact{" "}
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }

  //Larger Screens
  destroyDrawer() {
    const { classes } = this.props;
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Logo
            style={{
              width: "60px",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => window.open("https://ihc-attribution.com", "_self")}
          />

          <Button
            color="inherit"
            href="https://ihc-attribution.com"
            className={classes.firstLink}>
            Home
          </Button>
          <Button
            color="inherit"
            href="https://ihc-attribution.com/ihc-data-driven-attribution-model/"
            className={classes.navItem}>
            IHC attribution model
          </Button>
          <Button
            color="inherit"
            href="https://ihc-attribution.com/marketing-attribution-api/"
            className={classes.navItem}>
            API
          </Button>
          <Button
            color="inherit"
            href="https://ihc-attribution.com/attribution-service-contact/"
            className={classes.navItem}>
            Contact
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    return (
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

LoggedOutNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(LoggedOutNav);
