import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  TextField,
  Box,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import Nav from "../components/MainNav";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ChangePassword from "./ChangePassword";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { render } from "react-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  btn: {
    backgroundColor: "#BC343E",
    color: "white",
    display: "inline",
    "&:hover": {
      backgroundColor: `rgb(221, 108, 118)`,
    },
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [userAttr, setUserAttr] = useState();
  const [username, setUsername] = useState();
  const [usagePlan, setUsagePlan] = useState("");

  const [newName, setNewName] = useState();
  const [newSurname, setNewSurname] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [affiliation, setNewAffiliation] = useState("");
  const [newVAT, setNewVAT] = useState("");
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [dialogEditInfoOpen, setDialogEditInfoOpen] = useState(false);
  const [dialogChangePasswordOpen, setDialogChangePasswordOpen] =
    useState(false);
  const [isShownChangeUserInfo, setIsShownChangeUserInfo] = useState(false);
  const [showEditInfoDialog, setShowEditInfoDialog] = useState(true);
  const [isShownChangeUserPassword, setIsShownChangeUserPassword] =
    useState(false);
  const [updateUserInfo, setuUpdateUserInfo] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userInfoFormValidation, setUserInfoFormValidation] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserAttr(user.attributes);
      setUsername(user.username);
    });
  }, [updateUserInfo]);

  useEffect(() => {
    if (!userAttr) return;
    const apiName = "ApiGatewayRestApi";
    const path = "/";
    const myInit = {
      response: true,
      queryStringParameters: {
        userId: userAttr.sub,
        userEmail: userAttr.email,
      },
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        const plan = Object.keys(response.data[1])[0];
        if (plan.includes("paid")) {
          setUsagePlan("Paid");
        } else if (plan.includes("free")) {
          setUsagePlan("Free");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setFetchError(true);
      })
      .finally(() => {
        setLoading(false);
        setShowEditInfoDialog(true);
      });
  }, [userAttr]);
  // Auth.currentAuthenticatedUser().then((user) => {
  //   setUserAttr(user.attributes);
  //   setUsername(user.username);

  //   const apiName = "ApiGatewayRestApi";
  //   const path = "/";
  //   const myInit = {
  //     response: true,
  //     queryStringParameters: {
  //       userId: user.attributes.sub,
  //       userEmail: user.attributes.email,
  //     },
  //   };
  //   API.get(apiName, path, myInit)
  //     .then((response) => {
  //       const plan = Object.keys(response.data[1])[0];
  //       if (plan.includes("paid")) {
  //         setUsagePlan("Paid");
  //       } else if (plan.includes("free")) {
  //         setUsagePlan("Free");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // });
  //}, []);

  async function updateUser() {
    const user = await Auth.currentAuthenticatedUser();

    let result = await Auth.updateUserAttributes(user, {
      given_name: newName ? newName : userAttr.given_name,
      family_name: newSurname ? newSurname : userAttr.family_name,
      email: newEmail ? newEmail : userAttr.email,
      phone_number: newPhone ? newPhone : userAttr.phone_number,
      address: newAddress ? newAddress : userAttr.address,
      "custom:company": affiliation,
      "custom:VAT": newVAT,
    });
    if (result === "SUCCESS") {
      console.log(userAttr, userAttr["custom:company"], userAttr["custom:VAT"]);
      setSuccess(true);
      setNewName("");
      setNewSurname("");
      setNewPhone("");
      setNewEmail("");
      setNewAddress("");
      setNewAffiliation("");
      setNewVAT("");
      setuUpdateUserInfo(!updateUserInfo);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    updateUser(newEmail, newPhone, newAddress, affiliation, newVAT);
  };
  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {fetchError ? (
                <Typography>
                  <p className={classes.errorMessage}>
                    An error occured while accessing data. Please refresh the
                    page.
                  </p>
                </Typography>
              ) : !loading ? (
                <Paper
                  className={classes.paper}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      "justify-content": "left",
                      gap: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    <Typography variant="h4">User Data</Typography>
                    <Button
                      size="sm"
                      variant="contained"
                      color="success"
                      className={classes.btn}
                      onClick={() => {
                        setIsShownChangeUserInfo(!isShownChangeUserInfo);
                        setSuccess(false);
                        setDialogEditInfoOpen(true);
                      }}
                    >
                      <Typography variant="h7">Edit Info</Typography>
                    </Button>

                    <Dialog
                      open={dialogEditInfoOpen}
                      onClose={() => {
                        setDialogEditInfoOpen(false);
                      }}
                      fullWidth
                      maxWidth="md"
                    >
                      <DialogTitle>
                        Edit Info
                        {success ? (
                          <Collapse in={success}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setSuccess(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              <Typography variant="h6" align="left">
                                Change successful! <br />
                              </Typography>
                            </Alert>
                          </Collapse>
                        ) : null}
                      </DialogTitle>

                      <DialogContentText>
                        {!showEditInfoDialog ? (
                          <div
                            style={{ textAlign: "center", marginTop: "3rem" }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <Box>
                            <form
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                              className={classes.root}
                              noValidate
                              autoComplete="off"
                              onSubmit={handleSubmit}
                            >
                              <TextField
                                required
                                style={{ marginRight: "1rem" }}
                                label="First Name"
                                variant="outlined"
                                defaultValue={userAttr.given_name}
                                onChange={(e) => {
                                  setNewName(e.target.value);
                                  e.target.value === ""
                                    ? setUserInfoFormValidation(false)
                                    : setUserInfoFormValidation(true);
                                }}
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                required
                                label="Surname"
                                variant="outlined"
                                defaultValue={userAttr.family_name}
                                onChange={(e) => {
                                  setNewSurname(e.target.value);
                                  e.target.value === ""
                                    ? setUserInfoFormValidation(false)
                                    : setUserInfoFormValidation(true);
                                }}
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                required
                                label="Email"
                                variant="outlined"
                                defaultValue={userAttr.email}
                                onChange={(e) => {
                                  setNewEmail(e.target.value);
                                  e.target.value === ""
                                    ? setUserInfoFormValidation(false)
                                    : setUserInfoFormValidation(true);
                                }}
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                required
                                label="Phone Number"
                                variant="outlined"
                                defaultValue={userAttr.phone_number}
                                helperText="Please include your country's prefix"
                                onChange={(e) => {
                                  setNewPhone(e.target.value);
                                  e.target.value === ""
                                    ? setUserInfoFormValidation(false)
                                    : setUserInfoFormValidation(true);
                                }}
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                required
                                defaultValue={userAttr.address}
                                label="Address"
                                variant="outlined"
                                onChange={(e) => {
                                  setNewAddress(e.target.value);

                                  e.target.value === ""
                                    ? setUserInfoFormValidation(false)
                                    : setUserInfoFormValidation(true);
                                }}
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                defaultValue={userAttr["custom:company"]}
                                label="Affiliation/Company"
                                variant="outlined"
                                onChange={(e) =>
                                  setNewAffiliation(e.target.value)
                                }
                              />
                              <TextField
                                style={{
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                defaultValue={userAttr["custom:VAT"]}
                                label="VAT"
                                variant="outlined"
                                onChange={(e) => setNewVAT(e.target.value)}
                              />

                              <Button
                                disabled={!userInfoFormValidation}
                                type="submit"
                                variant="contained"
                                className={classes.btn}
                                onClick={() => {
                                  setShowEditInfoDialog(false);
                                  updateUser();
                                }}
                              >
                                Update user info
                              </Button>
                            </form>
                          </Box>
                        )}
                      </DialogContentText>
                    </Dialog>
                    <Button
                      size="sm"
                      className={classes.btn}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setIsShownChangeUserPassword(
                          !isShownChangeUserPassword
                        );
                        setDialogChangePasswordOpen(true);
                      }}
                    >
                      <Typography variant="h7">Change Password</Typography>
                    </Button>

                    <Dialog
                      open={dialogChangePasswordOpen}
                      onClose={() => {
                        setDialogChangePasswordOpen(false);
                      }}
                      fullWidth
                      maxWidth="md"
                    >
                      <DialogTitle>Change Password</DialogTitle>
                      <DialogContentText>
                        <ChangePassword />
                      </DialogContentText>
                    </Dialog>
                  </Box>

                  <Box style={{ gap: "0.2em" }}>
                    <Typography variant="h5">
                      <b>Username:</b> {username}
                    </Typography>
                    <Typography variant="h5">
                      <b>Name:</b> {userAttr.given_name}
                    </Typography>
                    <Typography variant="h5">
                      <b>Surname</b> {userAttr.family_name}
                    </Typography>
                    <Typography variant="h5">
                      <b>Email:</b> {userAttr.email}
                    </Typography>
                    <Typography variant="h5">
                      <b>Phone number</b>: {userAttr.phone_number}
                    </Typography>
                    {userAttr.address ? (
                      <Typography variant="h5">
                        <b>Address</b>: {userAttr.address}
                      </Typography>
                    ) : (
                      <Typography variant="h5">
                        <b>Address:</b> not provided
                      </Typography>
                    )}
                    {userAttr["custom:company"] ? (
                      <Typography variant="h5">
                        <b>Company</b>: {userAttr["custom:company"]}
                      </Typography>
                    ) : (
                      <Typography variant="h5">
                        <b>Company</b>: not provided
                      </Typography>
                    )}
                    {userAttr["custom:VAT"] ? (
                      <Typography variant="h5">
                        <b>VAT:</b> {userAttr["custom:VAT"]}
                      </Typography>
                    ) : (
                      <Typography variant="h5">
                        <b>VAT:</b> not provided
                      </Typography>
                    )}

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        "justify-content": "left",
                        gap: "1em",
                      }}
                    >
                      <Typography variant="h5">
                        <b>Your plan:</b> {usagePlan} (
                        {usagePlan === "Paid" ? "10 000" : "100"} API calls)
                      </Typography>
                      <Button
                        size="sm"
                        variant="contained"
                        className={classes.btn}
                        href="mailto:info@haensel-ams.com?subject=IHC Usage Plan Change"
                      >
                        Request change
                      </Button>
                    </Box>
                  </Box>
                  <Typography variant="body2">
                    <a href="https://docs.google.com/document/d/1GxASwpJaot2kDQ-lDqOYeLPGsJRlkJprXRWvy9wScFQ/edit?usp=sharing">
                      Terms and Conditions
                    </a>{" "}
                    applied
                  </Typography>
                </Paper>
              ) : (
                <div style={{ textAlign: "center", marginTop: "3rem" }}>
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Settings;
