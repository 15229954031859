import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  MenuItem,
  Collapse,
  Input,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import { useDebounce } from "use-debounce";
import "react-datepicker/dist/react-datepicker.css";
import Nav from "../components/MainNav";
import RecentTrainingsTable from '../components/RecentTrainingsTable'


const useStyles = makeStyles((theme) => ({
  dropzone: {
    height: "50%",
  },
  previewChip: {
    maxWidth:'87.5vw',
    fontSize:'0.9rem',
    fontWeight:'600',
    height:'3rem',
    padding: '0.5rem'
  },
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    display: 'inline-block',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnUpload: {
    backgroundColor: "#BC343E",
    color: "white",
    marginTop: "2rem",
    display: "block",
    "&:hover": {
      backgroundColor: "#BC343E",
    },
    "&:disabled": {
      backgroundColor: "#e5e5e5",
    },
  },
  linkButton: {
    color: "white",
    backgroundColor: "#385a64",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "borderBox",
    transition: ` background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    fontWeight: "500",
    lineHeight: " 1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: `#587e8a`,
    },
  },
  expertSettingsOpener: {
    fontSize: '0.9rem',
    color: '#385a64',
    cursor: 'pointer',
    '&:hover':{
      textDecoration: 'underline',
    }
  },
  expertSettingsCloser:{
    float: 'right',
    border: '1px solid #1a2e35',
    color: '#1a2e35',
    padding: '0.1rem 0.35rem',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#1a2e35',
      color: 'white'
    }
  }
}));

const Paramtraining = (props) => {
  const classes = useStyles();
  const [fileNames, setFileNames] = useState([]);
  const [existingConvTypes, setExistingConvTypes] = useState([]);
  const [selectedConvType, setSelectedConvType] = useState(null);
  const [doubleConvtypeCheck, setDoubleConvtypeCheck] = useState(false);
  const [convtypeStringCheck, setConvtypeStringCheck] = useState(false);
  const [expertSettings, setExpertSettings] = useState('')
  const [activeTrainings, setActiveTrainings] = useState([])

  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [newConvType, setNewConvType] = useState('');
  const [email, setEmail] = useState("");
  const [selectedFiles, setselectedFiles] = useState([]);

  const [trainingErr, setTrainingErr] = useState(false);
  const [checkedConvtype, setCheckedConvtype] = useState(false);
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);

  const [onloadMsg, setOnloadMsg] = useState("");
  const [expertSettingsFieldExpanded, setExpertSettingsFieldExpanded] =
    useState(false);

  const regex = /^[0-9a-zA-Z\-_]+$/;

  function validateUpload() {
    return (
      selectedFiles.length > 0 && (selectedConvType || newConvType != null)
    );
  }

  useEffect(() => {
    let isMounted = true
    const apiName = "ApiGatewayRestApi";
    const path = "/training";
    const myInit = {
      response: true,
      queryStringParameters: {
        userId: userId,
      },
    };
    if (userId) {
      API.get(apiName, path, myInit)
        .then((response) => {
          try{
            if(isMounted) setActiveTrainings(response.data.data)
          }catch{
            if(isMounted) setActiveTrainings([])
          }
        })
        .catch((error) => {
          console.log("err", error.response);
          if(isMounted) setOnloadMsg(
            "There was an error while accessing your recent trainings."
          );
        });
    }
    return() =>{isMounted = false}
  }, [userId]);


  useEffect(() => {
    let isMounted = true
    Auth.currentAuthenticatedUser().then((user) => {
      const startDateString = moment().format("YYYY-MM-DD");
      const endDateString = moment().subtract(30, "days").format("YYYY-MM-DD");
      if(isMounted){
        setUserEmail(user.attributes.email);
      setEmail(user.attributes.email);
      setUserId(user.attributes.sub);
      }
      

      const apiName = "ApiGatewayRestApi";
      const path = "/params";
      const myInit = {
        response: true,
        queryStringParameters: {
          userId: user.attributes.sub,
          startDate: endDateString,
          endDate: startDateString,
        },
      };

      API.get(apiName, path, myInit)
        .then((response) => {
          let convtypesArr = [];
          response.data[0].forEach((obj) => {
            convtypesArr.push(obj[Object.keys(obj)[0]]);
          });
          if(isMounted) setExistingConvTypes(convtypesArr);
        })
        .catch((error) => {
          console.log(error.response);
        });
    });

    return(() => {
      isMounted = false
    })
  }, []);

  const onFileChange = (file) => {
    setselectedFiles(file);

    file.forEach((item) => {
      setFileNames(item.name);
    });
  };

  const inputCheck = () => {
    setUploaded(false)
    if (selectedConvType || (newConvType && regex.test(newConvType))) {
      getUrl();
      setDoubleConvtypeCheck(false);
      setConvtypeStringCheck(false);
      setNewConvType("");
    } else {
      setConvtypeStringCheck(true);
    }
  };

  const getUrl = () => {
    const apiName = "ApiGatewayRestApi";
    const path = "/geturl";
    const myInit = {
      response: true,
      queryStringParameters: {
        userId: userId,
        userEmail: userEmail,
        filename: fileNames,
        convtype: selectedConvType ? selectedConvType : newConvType,
      },
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        uploadFile(
          selectedFiles[0],
          response.data.data.url,
          response.data.data.fields,
          response.data.data.fields.key
        );
      })
      .catch((error) => {
        console.log(error.response);
        setTrainingErr(true);
      });
  };

  const uploadFile = async (file, url, fields, key) => {
    let f = new FormData();
    f.append("key", fields.key);
    f.append("policy", fields.policy);
    f.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    f.append("x-amz-credential", fields["x-amz-credential"]);
    f.append("x-amz-date", fields["x-amz-date"]);
    f.append("x-amz-security-token", fields["x-amz-security-token"]);
    f.append("x-amz-signature", fields["x-amz-signature"]);
    f.append("file", file);
    return fetch(url, {
      method: "POST",
      body: f,
    }).then(post(key));
  };

  const post = (key) => {
    let splitString;
    if (email) {
      splitString = email.replace(/[ ,]+/g, ",").split(",");
    }

    const bodyArr = [];
    bodyArr.push(userId);
    bodyArr.push(userEmail);
    bodyArr.push(splitString ? splitString : "none");
    bodyArr.push(key);
    bodyArr.push(expertSettings)

    const apiName = "ApiGatewayRestApi";
    const path = "/upload";
    const myInit = {
      body: bodyArr,
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        if (response) {
          setKey((prevKey) => prevKey + 1);
          setUploaded(true);
          setselectedFiles([]);
          setEmail(userEmail);
          setNewConvType("");
          setExpertSettings('');
          setExpertSettingsFieldExpanded(false)
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  const handleNewConvType = (e) => {
    if (convtypeStringCheck && regex.test(e.target.value)) {
      setConvtypeStringCheck(false);
    }
    if (selectedConvType) {
      setDoubleConvtypeCheck(true);
    } else {
      setNewConvType(e.target.value);
    }
  };

  const handleExpertSettingsChange = (e) => {
    setExpertSettings(e.target.value)
  }
  const resetExpertSettings = () =>{
    //Set Expert settings fields empty
    setExpertSettings('')
    setExpertSettingsFieldExpanded(false)
  }
  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {checkedConvtype ? (
                <Collapse in={checkedConvtype}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setCheckedConvtype(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body1" align="left">
                      Please select or add a new convtype
                    </Typography>
                  </Alert>
                </Collapse>
              ) : null}
              {onloadMsg ? (
                <Collapse in={onloadMsg}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOnloadMsg("");
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body1" align="left">
                      {onloadMsg}
                    </Typography>
                  </Alert>
                </Collapse>
              ) : null}
              {doubleConvtypeCheck ? (
                <Collapse in={doubleConvtypeCheck}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setDoubleConvtypeCheck(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body1" align="left">
                      Please select an existing conversion type OR create a new
                      one. Both is not possible.
                    </Typography>
                  </Alert>
                </Collapse>
              ) : null}

              <Typography variant="h4" className={classes.paddedHeading}>
                Parameter training
              </Typography>
              <RecentTrainingsTable recentTrainings={activeTrainings}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <Typography variant="h6">
                  Choose an existing conversion type OR create a new one
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{ width: "90%" }}
                >
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    style={{ marginTop: "1rem" }}
                  >
                    <InputLabel id="select-label">
                      Choose an existing conversion type
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      defaultValue=""
                      onChange={(e) => setSelectedConvType(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>select a value</em>
                      </MenuItem>
                      {existingConvTypes.map((txt, keyIndex) => (
                        <MenuItem key={keyIndex} value={txt}>
                          {txt}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    error={convtypeStringCheck}
                    fullWidth
                    style={{ marginTop: "1rem" }}
                  >
                    {convtypeStringCheck ? (
                      <InputLabel htmlFor="convtype-input">
                        Invalid format
                      </InputLabel>
                    ) : (
                      <InputLabel htmlFor="convtype-input">
                        Create a new conversion type
                      </InputLabel>
                    )}

                    <Input
                      id="convtype-input"
                      value={newConvType}
                      onChange={handleNewConvType}
                    />
                    <FormHelperText id="my-helper-text">
                      No empty spaces, accents or special characters
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <InputLabel htmlFor="my-input">
                      Add contact emails for training results
                    </InputLabel>
                    <Input
                      id="my-input"
                      aria-describedby="my-helper-text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      Use comma separation to add multiple emails
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "1rem" }}>
              
                    {!expertSettingsFieldExpanded ?
                      (
                        <span
                        className={classes.expertSettingsOpener}
                        onClick={() => {
                          setExpertSettingsFieldExpanded(
                            !expertSettingsFieldExpanded
                          );
                        }}
                      >
                        {" "}
                         > Add expert settings
                      </span>
                      ) :
                    (
                      <>
                        <InputLabel htmlFor="expert-settings">
                          Add a value for expert settings
                        </InputLabel>
                        <Input
                          value={expertSettings}
                          id="expert-settings"
                          aria-describedby="my-helper-text"
                          onChange={handleExpertSettingsChange}
                        />
                        <FormHelperText id="my-helper-text">
                          Example: &#123;"initializer_quantile": 0.8&#125;
                          <span className={classes.expertSettingsCloser} onClick={resetExpertSettings}>Cancel </span>
                        </FormHelperText>
                        
                      </>
                    )}
                  </FormControl>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <Typography variant="h6">
                  You can only upload one datafile for parameter training. The
                  maximum file size is 300 MB. Use either CSV or JSON format,
                  here are two example files for each format:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  my={5}
                >
                  <a
                    href="/ihc_parameter_training_set.json"
                    download
                    className={classes.linkButton}
                  >
                    Download JSON
                  </a>
                  <br />
                  <a
                    href="/ihc_parameter_training_set.csv"
                    download
                    className={classes.linkButton}
                  >
                    Download CSV
                  </a>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {trainingErr ? (
                <Collapse in={trainingErr}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setTrainingErr(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body1" align="left">
                      IHC training still in progress, try again when training is
                      complete. You will receive an email from us when it is
                      done.
                    </Typography>
                  </Alert>
                </Collapse>
              ) : null}
              {uploaded ? (
                <Collapse in={uploaded}>
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setUploaded(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body1" align="left">
                      Upload successful! The training will start in a couple of seconds! <br/> Please do not upload another file with the same conversion type until your training starts, as that may lead to unintended overwrites. <br /> We will email you when the
                      training is complete. 
                      {/* and your new parameter are usable for API calls.  */}
                      This might take a couple of hours.
                    </Typography>
                  </Alert>
                </Collapse>
              ) : null}
              <DropzoneArea
                onChange={onFileChange}
                key={debounceKey}
                filesLimit={1}
                maxFileSize={100000000}
                acceptedFiles={[".json", ".csv"]}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: "row" },
                }}
                previewChipProps={{ classes: { outlined: classes.previewChip } }}
                previewText="Selected files"
                className={classes.dropzone}
              />

              <Button
                disabled={!validateUpload()}
                onClick={inputCheck}
                size="large"
                className={classes.btnUpload}
                fullWidth={true}
              >
                Submit dataset for training
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Paramtraining;
