import React from "react";
import Nav from "../components/MainNav";
import {
    Container,
    Paper,
    Grid,
    makeStyles,
  } from "@material-ui/core";
  const useStyles = makeStyles((theme)=>({
    root: {
        display: "flex",
      },
      appBarSpacer: theme.mixins.toolbar,
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: "85%",
      },
      paper: {
        padding: theme.spacing(2),
        height: "100%",
      },
      content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      },
  }))
function OverviewError() {
    const classes = useStyles();
  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <p>
                    There was an error accessing your data. Please check your
                    internet connection and refresh the page.
                  </p>
                  <p>
                    If the problem persist, please contact us at{" "}
                    <a href="mailto:info@haensel-ams.com">
                      info@haensel-ams.com
                    </a>
                  </p>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default OverviewError;
