import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function OverviewCustomerJourneys(props) {


  if(props.data.length) return (
    <div style={{ width: "auto", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          width={1000}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Request_date" />
          <YAxis />
          <Tooltip/>
          <Bar dataKey="Computed_customer_journeys" fill="#385a64" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
  else return (
    <div>
      <p>You don't have any curstomer journeys in the selected date range.</p>
    </div>
  )
}
