import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { forwardRef } from "react";
import { Grid } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mTableWrapper: {
    display: "flex",
    marginLeft: "-0.5rem",
  },
  tableSubtitle: {
    margin: "0.25rem 1rem",
    fontSize: "0.9rem",
    color: "rgb(56, 90, 100)",
    fontWeight: 600,
  },
  totalsRow: {
    display: "flex",
    minHeight: "3rem",
    background: "#385a64",
    width: "100%",
    overflow: "auto",
  },
  totalCell: {
    margin: "auto",
    marginBottom: "0.5rem",
    fontWeight: 600,
    flexBasis: "33%",
    fontSize: "1rem",
    color: "white",
    borderRight: "1px solid white",
    flexDirection: "column",

    textAlign: "center",
    "@media (max-width: 1500px)": {
      fontSize: "0.9rem",
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function IHCtable(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dates, setDates] = useState({});
  const [totals, setTotals] = useState({
    convType: 0,
    cj: 0,
    apiCalls: 0,
  });

  useEffect(() => {
    if (props !== null) {
      let data = props.tableData.sort((a, b) => {
        return b.Computed_customer_journeys - a.Computed_customer_journeys;
      });
      setData(data);
    }
  }, [props]);

  useEffect(() => {
    if (!data.length) return;
    let { cj, apiCalls } = calculateTotals();
    setTotals({
      convType: data.length,
      cj: cj,
      apiCalls: apiCalls,
    });
  }, [data]);

  const calculateTotals = () => {
    let totalCJ = 0;
    let totalAPIcalls = 0;
    data.forEach((obj) => {
      totalCJ += obj.Computed_customer_journeys;
      totalAPIcalls += obj.api_calls;
    });
    return {
      cj: totalCJ,
      apiCalls: totalAPIcalls,
    };
  };
  const formatDate = (d) => {
    try {
      if (!d) return;
      let result = moment(d).format("DD/MM/YYYY");
      return result;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (props.dates !== null) {
      let start = formatDate(props.dates.start);
      let end = formatDate(props.dates.end);
      if (!end && start) end = start;

      setDates({ start: start, end: end });
    }
  }, [props]);

  useEffect(() => {
    if (props.data !== null) {
      props.tableData.forEach((obj) => {
        let lastDate = obj.Last_Training_Date;
        lastDate = lastDate.split("-").reverse().join("/");
        obj.Last_Training_Date = lastDate;
      });
    }
  }, [props]);

  if (data.length)
    return (
      <div>
        <MaterialTable
          icons={tableIcons}
          title={`Conversion Type Statistics`}
          localization={{
            body: {
              emptyDataSourceMessage: "No records to display",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          components={{
            Toolbar: (props) => (
              <>
                <div
                  style={{ position: "relative" }}
                  className={classes.mTableWrapper}
                >
                  <MTableToolbar {...props} style={{ flexGrow: "1" }} />
                </div>
                <h4 className={classes.tableSubtitle}>
                  {" "}
                  View usage statistics per conversion type.
                </h4>
              </>
            ),
            Pagination: (props) => (
              <div className={classes.totalsRow}>
                <span className={classes.totalCell}>
                  <p style={{ fontSize: "0.8rem", color: "white" }}>
                    Total Conversion Types
                  </p>
                  {totals.convType}{" "}
                </span>
                <span className={classes.totalCell}>
                  <p style={{ fontSize: "0.8rem", color: "white" }}>
                    Total Customer Journeys
                  </p>
                  {totals.cj}{" "}
                </span>
                <span className={classes.totalCell}>
                  <p style={{ fontSize: "0.8rem", color: "white" }}>
                    Total API Calls
                  </p>
                  {totals.apiCalls}{" "}
                </span>
              </div>
            ),
          }}
          options={{
            searchFieldStyle: { marginLeft: "-25px" },
            headerStyle: { position: "sticky", fontWeight: 600 },
            pageSize: data.length,
            maxBodyHeight: 700,
          }}
          columns={[
            {
              title: "Conv Type ID",
              field: "Conv_Type_ID",
            },
            {
              title: "Status",
              field: "Conv_Type_Status",
              width: "15px",
            },

            {
              title: "Computed Customer Journeys",
              field: "Computed_customer_journeys",
              cellStyle: { paddingLeft: "1.75rem" },
            },
            {
              title: "API calls",
              field: "api_calls",
              cellStyle: { paddingLeft: "1.75rem" },
            },
            // { title: "Last Training Date", field: "Last_Training_Date", width: "50px", },
          ]}
          data={data}
        />
      </div>
    );
  else return <div></div>;
}
