import React, { useState, useEffect } from "react";
import "./App.css";
import { Hub } from "@aws-amplify/core";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Typography, Grid, Collapse } from "@material-ui/core";
import Amplify from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Overview from "./containers/Overview";
import IHCparams from "./containers/IHCparams";
import Paramtraining from "./containers/Paramtraining";
import LoggedOutNav from "./components/LoggedOutNav";
import Settings from "./containers/Settings";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as LandingImg } from "./assets/analytics.svg";
import history from "./history";
import SignUpForm from './components/AmplifyAuthComponents/SignUpForm'
import SignInForm from './components/AmplifyAuthComponents/SignInForm'
import ConfirmSignUp from './components/AmplifyAuthComponents/ConfirmSignUp'
import ResetPasswordForm from './components/AmplifyAuthComponents/ResetPasswordForm'
import ConversionTypeAdmin from "./containers/ConversionTypeAdmin";

Amplify.configure({
  Auth: {
    identityPoolId: "eu-central-1:37e4b95b-ec66-4953-a9c7-30cf0cc871ca",
    region: "eu-central-1",
    userPoolId: "eu-central-1_0vjhkXNr0",
    userPoolWebClientId: "1ior48inf1kjj2e82qmsvnavmh",
  },
  API: {
    endpoints: [
      {
        name: "ApiGatewayRestApi",
        endpoint: "https://wbw0xsumqh.execute-api.eu-central-1.amazonaws.com/dev",
      },
    ],
  },
});

const useStyles = makeStyles((theme) => ({
  backgroundImg: {
    // width: "100%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    position: 'fixed',
    top: '7.5rem'
  },
  imgWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
}));


const AuthStateApp = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [err, setErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  //State to determine which form to show
  const [formState, setFormState] = useState('sign-in')
  const [userToBeConfirmed, setUserToBeConfirmed] = useState({})

  const classes = useStyles();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      if(nextAuthState !== 'confirmSignup'){
        setUser(authData);
        sessionStorage.setItem('current_ihc_user', authData)
      }
    });
  });

  useEffect(() => {
    Hub.listen("UI Auth", (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
  });

 
  const onAuthEvent = (payload) => {
    if (payload.event === "ToastAuthError" && payload.message) {
      setErr(true);
      setErrorMsg(payload.message?.trim());
    } else if (payload.message === "signedin") {
      history.push("/home");
    } else if (payload.message === "signedout") {
      history.push("/");
    }else if (payload.message === "confirmSignup") {
      setUserToBeConfirmed(payload.data.user)
      setFormState('confirm-sign-up')
    }
  };



  return user||sessionStorage.current_ihc_user ? (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/home" render={() => <Overview />} />
            <Route exact path="/params" render={() => <IHCparams />} />
            <Route exact path="/training" render={() => <Paramtraining />} />
            <Route exact path="/conversion-types" render={() => <ConversionTypeAdmin />} />
            <Route exact path="/settings" render={() => <Settings />} />
          </Switch>
        </div>
      </Router>
    </>
  ) : (
    <div>
      <LoggedOutNav />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div style={{ marginTop: "5rem" }}>
              {formState === 'sign-in' &&
                <SignInForm setFormState={setFormState} onAuthEvent={onAuthEvent}/>
              } 
               {formState === 'sign-up' &&
                <SignUpForm setFormState={setFormState}></SignUpForm>
               }
               {formState === 'confirm-sign-up' &&
                <ConfirmSignUp user={userToBeConfirmed} setFormState={setFormState}/>
               }
               {
                 formState === 'reset-password' &&
                 <ResetPasswordForm setFormState={setFormState} />
               }
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={classes.imgWrapper}>
          <div>
            {err ? (
              <Collapse
                in={err}
                style={{ position: "fixed", bottom: "100px", left: "10px" }}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErr(false);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  <Typography variant="h6" align="left">
                    {errorMsg}
                  </Typography>
                </Alert>
              </Collapse>
            ) : null}
            <LandingImg className={classes.backgroundImg}></LandingImg>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthStateApp;
