import React, { useEffect, useState } from "react";
import Amplify, { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Nav from "../components/MainNav";
import OverviewApiChart from "../components/OverviewApiChart";
import OverviewCustomerJourneys from "../components/OverviewCustomerJourneys";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import IHCtable from "../components/IHCtable";

import { Link } from "react-router-dom";
import Info from "@material-ui/icons/Info";
import DateRangeSelector from "../components/DateRangeSelector/DateRangeSelector";
import OverviewError from "../components/OverviewError";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "85%",
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  noConversionTypeFeedback: {
    padding: "1rem",
    marginBottom: "1rem",
    lineHeight: "2rem",
    border: "1px solid #385a64",
  },
  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [apiToken, setApiToken] = useState(null);
  const [apiCallsLeft, setApiCallsLeft] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("month");
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setFirstName(user.attributes.given_name);
      setUserId(user.attributes.sub);
      setLoading(true);
      const apiName = "ApiGatewayRestApi";
      const path = "/";
      const myInit = {
        response: true,
        queryStringParameters: {
          userId: user.attributes.sub,
          userEmail: user.attributes.email,
        },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          const secondResObj = response.data[1];
          const thirdResObj = response.data[2];
          setApiToken(response.data[0].user_api_key);
          setApiCallsLeft(
            secondResObj[Object.keys(secondResObj)[0]].remaining_in_month
          );
          applyCurrentMonthFilter();
        })
        .catch((error) => {
          setFetchError(true);
          console.log(error);
        });
    });
  }, []);

  useEffect(() => {
    if (!startDate || !endDate || !userId) return;
    updateData(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    formatChartData(chartData);
  }, [chartData]);

 

  const updateData = (start, end) => {
    setLoading(true);
    const reformattedStart = moment(start).format("YYYY-MM-DD");
    const reformattedEnd = moment(end).format("YYYY-MM-DD");

    const apiName = "ApiGatewayRestApi";
    const path = "/params";
    const myInit = {
      response: true,
      queryStringParameters: {
        userId: userId,
        startDate: reformattedStart,
        endDate:
          reformattedEnd !== "Invalid date" ? reformattedEnd : reformattedStart,
      },
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        setTableData(response.data[1]);
        setChartData(response.data[2]);
      })
      .catch((error) => {
        console.log(error.response);
        setFetchError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteConversionType = (data) => {
    const typeId = data.Conv_Type_ID;

    let confirmDeletion = window.confirm(
      `This will delete the data related to the conversion type: ${typeId}. Are you sure you want to proceed?`
    );
    if (!confirmDeletion) return;

    const requestBody = {
      conversion_type_id: typeId,
      user_id: userId,
    };

    const apiName = "ApiGatewayRestApi";
    const path = "/delete_conversion_type";
    const myInit = {
      body: requestBody,
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        if (response) {
          updateData(startDate, endDate);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  const formatChartData = (arr) => {
    try {
      arr.forEach((el) => {
        let date = el.Request_date;
        let formattedDate = date.split("-").reverse().join("/");
        el.Request_date = formattedDate;
      });
    } catch {
      return arr;
    }
  };
  const applyCurrentMonthFilter = () => {
    setSelectedFilter("month");
    let date = new Date();
    const start = moment(date).startOf("month").toDate();
    const end = moment(date).endOf("month").toDate();

    setStartDate(start);
    setEndDate(end);
  };

  if (fetchError) return <OverviewError />;

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant="h4" className={classes.paddedHeading}>
            Welcome, {firstName}.
          </Typography>
          {!tableData.length && !loading && (
            <Paper className={classes.noConversionTypeFeedback}>
              <h4> You don't have any conversion types.</h4>
              <ul>
                <li class>
                  Click <Link to="/training">here</Link> to define a conversion
                  type and start training.
                </li>
                <li>
                  Click{" "}
                  <a
                    href="https://docs.google.com/document/d/1_ODYH0wjhsHwZ4hTVdmKWhbbVXXXSpTuas2fhPFTQFE/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                  to see our getting started guide.
                </li>
              </ul>
            </Paper>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  {apiToken ? (
                    <Typography
                      variant="h6"
                      className={classes.paddedHeading}
                      style={{ wordWrap: "break-word" }}
                    >
                      Your API token is: <br /> {apiToken}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h6"
                      className={classes.paddedHeading}
                      style={{ wordWrap: "break-word" }}
                    >
                      You don't have an API token yet
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  {apiCallsLeft ? (
                    <Typography variant="h6" className={classes.paddedHeading}>
                      You have: <br /> {apiCallsLeft} API calls left this month
                    </Typography>
                  ) : (
                    <Typography variant="h6" className={classes.paddedHeading}>
                      You don't have any API calls available
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "1rem" }}>
                <DateRangeSelector
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  showDatePicker={showDatePicker}
                  setShowDatePicker={setShowDatePicker}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="h6"
                        className={classes.paddedHeading}
                      >
                        Your latest API calls
                      </Typography>
                      <Tooltip
                        placement="top"
                        title="Count of calls/ requests to the API endpoint. (Note that one API call should usually contain multiple customer journeys.)"
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "1rem",
                            cursor: "pointer",
                            marginTop: "2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {chartData ? (
                        <OverviewApiChart data={chartData} />
                      ) : (
                        <Typography
                          variant="h6"
                          align="center"
                          style={{ color: "#e5e5e5" }}
                          className={classes.paddedHeading}
                        >
                          No API calls to display
                        </Typography>
                      )}
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="h6"
                        className={classes.paddedHeading}
                      >
                        Your latest computed customer journeys
                      </Typography>
                      <Tooltip
                        placement="top"
                        title="Count of successfully computed and return customer journeys. One  customer journey is the block of sessions connected with one conversion. So one customer journey equals one conversion, and it can contain multiple sessions."
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "1rem",
                            cursor: "pointer",
                            marginTop: "2rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {chartData ? (
                        <OverviewCustomerJourneys data={chartData} />
                      ) : (
                        <Typography
                          variant="h6"
                          align="center"
                          style={{ color: "#e5e5e5" }}
                          className={classes.paddedHeading}
                        >
                          No API calls to display
                        </Typography>
                      )}
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: "1rem", marginBottom: "5rem" }}
              >
                {loading ? (
                  <div style={{ textAlign: "center", marginTop: "3rem" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <IHCtable
                    tableData={tableData !== null ? tableData : []}
                    dates={{ start: startDate, end: endDate }}
                    deleteConversionType={deleteConversionType}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
