import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles } from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mTableWrapper: {
    display: "flex",
    marginLeft: "-0.5rem",
  },
  tableSubtitle: {
    margin: "0.25rem 1rem",
    fontSize: "0.9rem",
    color: "rgb(56, 90, 100)",
    fontWeight: 600,
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ConversionTypesAdminTable({
  userId,
  data,
  updateTable,
}) {
  const classes = useStyles();
  const [activeConversionTypes, setActiveConversionTypes] = useState([])

  useEffect(() => {
    if (data == null) return;
    setActiveConversionTypes(filterInactiveTypes(data))
  }, []);

  useEffect(() => {
    if (activeConversionTypes == []) return;
    formatTableData(activeConversionTypes);
  }, [activeConversionTypes]);

  const filterInactiveTypes = (data) => {
    return data.filter((obj)=> obj.Conv_Type_Status == 'Active')
  }

  const formatTableData = (arr) => {
    arr.forEach((obj) => {
      obj.formattedLastTrainingDate = formatLastTrainingDate(obj.Last_Training_Date);
    });
  };
  const formatLastTrainingDate = (d) => {
    let result = d.slice().split("-").reverse().join("/")
    return result;
  };

  const deleteConversionType = (conversionTypeId) => {
    let confirmDeletion = window.confirm(
      `This will delete the data related to the conversion type: ${conversionTypeId}. Are you sure you want to proceed?`
    );
    if (!confirmDeletion) return;

    const requestBody = {
      conversion_type_id: conversionTypeId,
      user_id: userId,
    };

    const apiName = "ApiGatewayRestApi";
    const path = "/delete_conversion_type";
    const myInit = {
      body: requestBody,
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        if (response) {
          alert("Delete succesfull");
          updateTable();
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        title={`List of Active Conversion Types`}
        localization={{
          header: {
            actions: "Delete",
          },
          body: {
            emptyDataSourceMessage: "No records to display",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
        components={{
          Toolbar: (props) => (
            <>
            <div
              style={{ position: "relative"}}
              className={classes.mTableWrapper}>
              <MTableToolbar {...props} style={{ flexGrow: "1"}} />
            </div>
            <h4 className={classes.tableSubtitle}> Delete conversion types by clicking the <span style={{fontWeight: 600}}>X</span>.</h4>
            </>
          ),
        }}
        actions={[
          {
            icon: tableIcons.Clear,
            tooltip: "Delete Conversion Type",
            onClick: (event, rowData) => {
              deleteConversionType(rowData.Conv_Type_ID);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          searchFieldStyle: { marginLeft: "-25px" },
          paging:false,
          headerStyle: { position: 'sticky', fontWeight:600},
          paging: false,
          maxBodyHeight: 600
        }}
        columns={[
          {
            title: "Conv Type ID",
            field: "Conv_Type_ID",
            width: "150px",
          },
          {
            title: "Last Training Date",
            field: "formattedLastTrainingDate",
            width: "300px",
            customSort: (a, b) =>{
              return Date.parse(a.Last_Training_Date) - Date.parse(b.Last_Training_Date)
            } 
          },
        ]}
        data={activeConversionTypes}
      />
    </div>
  );
}
