import React from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "80%",
    margin: "auto",
    padding: "1rem",
    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 15%)",
    borderRadius: "5px",
    minHeight: "65vh",
    display: "flex",
    flexDirection: 'column'
  },
  signupForm: {
    width: "80%",
    height: "auto",
    margin: "auto",
  },
  formInput: {
    margin: "0.5rem",
  },
  phoneNumberLine: {
    display: "flex",
    margin: "0.5rem",
    width: "100%",
    justifyContent: "space-between",
  },
  errorMessage: {
    minHeight: "1.5rem",
    padding: "1rem",
    margin: "0.5rem",
    border: "2px solid crimson",
    borderRadius: "5px",
    color: "crimson",
    fontSize: "0.9rem",
  },
  succesMessage:{
    height: "3rem",
    padding: "2rem",
    margin: "auto",
    border: "2px solid #216d21",
    borderRadius: "5px",
    color: "#216d21",
    fontSize: "1",
  },
  submitSection:{
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0.5rem'
  },
  navigatorLink:{
    color: '#3f51b5',
    textDecoration:'underline',
    cursor:'pointer',
    marginTop:'0.25rem'
  },
  submitButton:{
    color: 'white',
    fontWeight:'600',
    background: '#BC373E',
    margin: 'auto 0'
  }
}));

export default function ResetPasswordForm({setFormState }) {
  const classes = useStyles();

  const [formFields, setFormFields] = useState({
    username: '',
    verification_code: '',
    new_password: ''
  });
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState('')
  const [verificationCodeSent,setVerificationCodeSent] = useState(false)

  const onChange = (e) => {
    setFormError("");
    const { id, value } = e.target;
    let obj = { ...formFields };
    obj[id] = value;
    setFormFields(obj);
  };

  const requestVerificationCode = async() => {
    let formData = { ...formFields };
    try{
      await Auth.forgotPassword(formData.username)
      setSuccess('A verification code is sent to your email')
      setVerificationCodeSent(true)
    }catch(err){
      setVerificationCodeSent(false)
      console.log('Error while submitting username')
      setFormError(err.message)
    }
  }

  const onSubmit = async () => {
    let formData = { ...formFields };
    try {
      await Auth.forgotPasswordSubmit(
        formData.username,
        formData.verification_code,
        formData.new_password
      );      
      setSuccess('Password has been changed. You are being redirected to login page')

      setTimeout(()=>{
        setFormState('sign-in')
      },2000)

    } catch (err) {
      setFormError(err.message);
    }
  };

  return (
    <div className={classes.formContainer}>
      {success &&
          <div className={classes.succesMessage}>
            {success}
          </div>
        }
      {success[0] !== 'P'&&
      <form autoComplete="no" className={classes.signupForm}>
        {!verificationCodeSent&&
        <>
        <h3 style={{margin: '1rem 0.5rem'}}>Reset your password </h3>
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Username"
          id="username"
          onChange={(e) => {
            onChange(e);
          }}
        />
        </>
        }

        {verificationCodeSent&&
        <>
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Verification Code"
          id="verification_code"
          type="text"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="New Password"
          id="new_password"
          type="password"
          onChange={(e) => {
            onChange(e);
          }}
        />
        </>
        }
        
        {formError && <div className={classes.errorMessage}>{formError}</div>}


        <div className={classes.submitSection}>
          <div style={{padding: '0.5rem'}}>
            <span
              className={classes.navigatorLink}
              onClick={() => {
                setFormState("sign-in");
              }}
            >
              Back to Sign In
            </span>
          </div>
          {!verificationCodeSent&&
          <Button variant="contained" className={classes.submitButton} onClick={requestVerificationCode}>
            Submit
          </Button>
          }
          {verificationCodeSent&&
            <Button variant="contained" className={classes.submitButton} onClick={onSubmit}>
            Reset Password
          </Button>
          }
        </div>
      </form>
      }
    </div>
  );
}
