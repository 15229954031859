import React from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "80%",
    margin: "auto",
    padding: "1rem",
    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 15%)",
    borderRadius: "5px",
    minHeight: "65vh",
    display: "flex",
  },
  signupForm: {
    width: "80%",
    height: "auto",
    margin: "auto",
  },
  formInput: {
    margin: "0.5rem",
  },
  phoneNumberLine: {
    display: "flex",
    margin: "0.5rem",
    width: "100%",
    justifyContent: "space-between",
  },
  errorMessage: {
    minHeight: "1.5rem",
    padding: "1.25rem 0.75rem",
    margin: "1.5rem 0 0 0.5rem",
    textAlign: 'center',
    border: "2px solid #BC373E",
    borderRadius: "5px",
    fontSize: '0.95rem'
    // color: "crimson",
  },
  submitSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0.5rem",
  },
  inputLinks:{
    padding: '0.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  navigatorLink: {
    color: "#3f51b5",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
  submitButton: {
    color: "white",
    fontWeight: "600",
    background: "#BC373E",
    width: "100%",
  },
}));

export default function SignInForm({ onAuthEvent, setFormState }) {
  const classes = useStyles();

  const [formFields, setFormFields] = useState({
    username: "",
    password: "",
  });
  const [formError, setFormError] = useState("");

  const onChange = (e) => {
    setFormError("");
    const { id, value } = e.target;
    let obj = { ...formFields };
    obj[id] = value;
    setFormFields(obj);
  };

  const onSignIn = async () => {
    let formData = { ...formFields };
    try {
      let authenticatedUser = await Auth.signIn(formData);
      Hub.dispatch("UI Auth", {
        event: "AuthStateChange",
        data: authenticatedUser,
        message: "signedin",
      });
    } catch (err) {
      let errorCode = err.code === 'UserNotConfirmedException' ? 'Account not verified. Click "Verify your account" to get your code.' : err.message
      setFormError(errorCode);
    }
  };

  return (
    <div className={classes.formContainer}>
      <form autoComplete="no" className={classes.signupForm}>
        <h3 style={{ margin: "2rem 0.5rem" }}>Sign in to your account </h3>

        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Username"
          id="username"
          onChange={(e) => {
            onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSignIn();
            }
          }}
        />

        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Password"
          id="password"
          type="password"
          onChange={(e) => {
            onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSignIn();
            }
          }}
        />
        <div className={classes.inputLinks}>
        <span
            className={classes.navigatorLink}
            style={{ fontSize: "0.9rem" }}
            onClick={() => {
              setFormState("confirm-sign-up");
            }}
          >
            Verify your account
          </span>
          <span
            className={classes.navigatorLink}
            style={{ fontSize: "0.9rem"}}
            onClick={() => {
              setFormState("reset-password");
            }}
          >
            Reset password
          </span>
          
        </div>


        <div className={classes.submitSection}>
          <Grid container alignItems="baseline">
            <Grid item xs={12} md={8}>
              <div style={{ marginBottom: "1rem" }}>
                Don't have an account?
                <span
                  className={classes.navigatorLink}
                  style={{ fontWeight: 600, fontSize: "1.1rem" }}
                  onClick={() => {
                    setFormState("sign-up");
                  }}
                >
                  Sign up
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={onSignIn}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </div>
        {formError && <div className={classes.errorMessage}>{formError}</div>}

      </form>
    </div>
  );
}
