import React, { useState, useEffect } from "react";
import {
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

export default function ChannelsChart(props) {
  const [data, setData] = useState([]);
  const [height, setHeight] = useState(1000);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [ticks, setTicks] = useState([]);

  useEffect(() => {
    if (props.data) {
      if (props.data.length > 20) {
        setHeight(1200);
      }
      if (props.data.length > 30) {
        setHeight(1400);
      }

      setData(
        props.data.sort((a, b) =>
          a.channel > b.channel ? 1 : b.channel > a.channel ? -1 : 0
        )
      );

      const mins = [];
      const maximums = [];

      const inits = props.data.map((d) => d["initializer weight"]);
      const filteredInits = inits.filter((x) => x !== undefined);

      const holders = props.data.map((d) => d["holder weight"]);
      const filteredHolders = holders.filter((x) => x !== undefined);

      const closers = props.data.map((d) => d["closer weight"]);
      const filteredClosers = closers.filter((x) => x !== undefined);

      mins.push(Math.min(...filteredInits));
      mins.push(Math.min(...filteredHolders));
      mins.push(Math.min(...filteredClosers));

      maximums.push(Math.max(...filteredInits));
      maximums.push(Math.max(...filteredHolders));
      maximums.push(Math.max(...filteredClosers));

      const min = Math.min(...mins);
      const max = Math.max(...maximums);

      const minXAxis = Math.floor((min - 0.1) * 10) / 10;
      const maxXAxis = Math.floor((max + 0.1) * 10) / 10;

      setMinValue(minXAxis);
      setMaxValue(maxXAxis);

      const ticks = [minXAxis, maxXAxis];
      let i;
      for (i = minXAxis + 0.1; i < maxXAxis; i += 0.1) {
        ticks.push(parseFloat(i.toFixed(1)));
      }

      setTicks(ticks.sort());
    }
  }, [props.data]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ResponsiveContainer width="99%" height={1000}>
        <BarChart
          width={500}
          height={height}
          data={data}
          layout="vertical"
          margin={{ top: 15, right: 30, left: 50, bottom: 5 }}>
          <XAxis type="number" domain={[minValue, maxValue]} ticks={ticks} />
          <YAxis type="category" dataKey="channel" />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <ReferenceLine
            x="1"
            stroke="#707070"
            strokeWidth={2}
            label={{
              position: "top",
              value: "None weight level or average impact",
              fill: "#707070",
            }}
          />
          <Legend />
          <Bar dataKey="initializer weight" fill="#1a2e35" />
          <Bar dataKey="holder weight" fill="#385a64" />
          <Bar dataKey="closer weight" fill="#BC343E" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
