import React, { useState, useEffect } from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangeSelector.css";
import moment from "moment";
import { Button, CircularProgress } from "@material-ui/core";
import useWindowDimensions from "../../hooks/useWindowDimensions";


function DateRangeSelector({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedFilter,
  setSelectedFilter,
  showDatePicker,
  setShowDatePicker,
}) {
 
  const {windowWidth, windowHeight} = useWindowDimensions()
  const [monthsShown, setMonthsShown] = useState(0)

  useEffect(() => {
    setMonthsShown(resizeDatePicker(windowWidth))
  }, [windowWidth])
  

  useEffect(() => {
    applyCurrentMonthFilter();
  }, []);

  
  useEffect(() => {
    filterFutureDates();
  }, [endDate]);


  const applyCurrentMonthFilter = () => {
    setSelectedFilter("month");
    let date = new Date();
    const start = moment(date).startOf("month").toDate();
    const end = moment(date).endOf("month").toDate();

    setShowDatePicker(false);
    setStartDate(start);
    setEndDate(end);
  };

  const applyCurrentWeekFilter = () => {
    let today = new Date();
    let todayIndex = today.getDay();
    let startOfTheWeek = new Date(
      today.setDate(today.getDate() - (todayIndex - 1))
    );

    setSelectedFilter("current-week");
    setShowDatePicker(false);
    setStartDate(startOfTheWeek, new Date());
    setEndDate(new Date());
  };

  const applyLastWeekFilter = () => {
    setShowDatePicker(false);
    setSelectedFilter("last-week");
    let date = new Date();
    setStartDate(
      moment(date).subtract(1, "weeks").startOf("week").add(1, "day").toDate()
    );
    setEndDate(
      moment(date).subtract(1, "weeks").endOf("week").add(1, "day").toDate()
    );
  };

  const applyCustomDateFilter = (dates) => {
    const [start, end] = dates;

    if (!end) {
      setStartDate(start);
      setEndDate(end);
      return;
    } else {
      setSelectedFilter("custom");
      setStartDate(start);
      setEndDate(end);
      setShowDatePicker(false);
    }
  };

  const displayFormattedDate = (type) => {
    if (type === "start") {
      return (
        <span className="range-date">
          {startDate ? moment(startDate).format("DD/MM/YYYY") : "None Selected"}{" "}
          -
        </span>
      );
    } else {
      return (
        <span className="range-date">
          {endDate ? moment(endDate).format("DD/MM/YYYY") : "None Selected"}
        </span>
      );
    }
  };

  const filterFutureDates = () => {
    if (moment() < endDate) {
      setEndDate(moment().toDate());
    }
  };

  const formatCalendarView = () => {
    if (!endDate) return false;
    if (endDate - startDate > 2678400000) return false;
    if (moment() - startDate > 2000000000) return false;
    return true;
  };

  const closeDateRange = () => {
    if (!startDate || !endDate) applyCurrentMonthFilter();
    setShowDatePicker(false);
  };

  const resizeDatePicker = (width) => {
    if(width < 750) return 1
    if(width < 1050) return 2
    if(width < 1350) return 3
    return 4
  };

  
  return (
    <div>
      <div className="daterange-header">
        <span className="daterange-selected-dates">
          Show data in between:
          {displayFormattedDate("start")}
          {displayFormattedDate("end")}
        </span>
        <div className="daterange-header-buttons">
          <Button
            className={selectedFilter === "month" ? "selected-filter" : 'auto-range-button'}
            id="month"
            onClick={() => applyCurrentMonthFilter()}
          >
            current month
          </Button>
          <Button
            className={
              selectedFilter === "current-week" ? "selected-filter" : 'auto-range-button'
            }
            id="current-week"
            onClick={() => applyCurrentWeekFilter()}
          >
            current week
          </Button>
          <Button
            className={
              selectedFilter === "last-week" ? "selected-filter" : 'auto-range-button'
            }
            id="last-week"
            onClick={() => applyLastWeekFilter()}
          >
            last week
          </Button>
          <Button
            className={
              selectedFilter === "custom" && !showDatePicker
                ? "selected-filter"
                : 'auto-range-button'
            }
            id="custom"
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
          >
            {showDatePicker ? <></> : <>select dates</>}
          </Button>
        </div>
      </div>

      {showDatePicker && (
        <div className="date-filter-container">
          <DatePicker
            wrapperClassName="datepicker"
            startDate={startDate}
            selected={startDate}
            endDate={endDate}
            selectsRange
            inline
            showPreviousMonths={formatCalendarView()}
            monthsShown={monthsShown}
            isClearable
            filterDate={(endDate) => {
              return moment() > endDate;
            }}
            onChange={(dates) => {
              applyCustomDateFilter(dates);
            }}
          />
          <div className="datepicker-buttons">
            <Button
              variant="outlined"
              onClick={closeDateRange}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateRangeSelector;
