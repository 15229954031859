import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Text,
  Label,
  ResponsiveContainer,
} from "recharts";

export default function InitializerGraph(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data) {
      const hours_to_prior_session =
        props.data.init_membership_fun.hours_to_prior_session;

      const initializer_membership =
        props.data.init_membership_fun.initializer_membership;

      var newArray = hours_to_prior_session.map(function (e, i) {
        return {
          "hours to prior session": e,
          "initializer membership": initializer_membership[i],
        };
      });
      setData(newArray);
    }
  }, [props.data]);

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-200}
        dy={40}
        textAnchor="start"
        width={180}
        transform="rotate(-90)">
        Initializer membership
      </Text>
    );
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ResponsiveContainer width="99%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hours to prior session" interval={0} type="number">
            <Label
              value="Hours to prior session"
              position="insideTopLeft"
              offset={23}
            />
          </XAxis>
          <YAxis label={<CustomizedLabelB />} />
          <Tooltip
            labelFormatter={(name) => "hours to prior session: " + name}
          />
          <Line
            type="monotone"
            dataKey="initializer membership"
            stroke="#1a2e35"
            activeDot={{ r: 8 }}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
