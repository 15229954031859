import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Collapse, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#BC343E",
    color: "white",
    marginTop: "2rem",
    display: "inline",
    "&:hover": {
      backgroundColor: `rgb(221, 108, 118)`,
    },
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  function validateForm() {
    return (
      oldPassword.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      let result = await Auth.changePassword(
        currentUser,
        oldPassword,
        password
      );
      if (result === "SUCCESS") {
        setSuccess(true);
        setOldPassword("");
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  }

  return (
    <div className="ChangePassword">
     
      {success ? (
        <Collapse in={success}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccess(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            <Typography variant="h6" align="left">
              Change successful!
            </Typography>
          </Alert>
        </Collapse>
      ) : null}
      {error ? (
        <Collapse in={error}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            <Typography variant="h6" align="left">
              {errorMsg}
            </Typography>
          </Alert>
        </Collapse>
      ) : null}
      <form
        onSubmit={handleChangeClick}
        style={{ display: "flex", flexDirection: "column" }}
        autoComplete="off">
        <TextField
          style={{
            marginRight: "1rem",
          }}
          label="Old Password"
          type="password"
          variant="outlined"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
          }}
          label="New Password"
          helperText="Min 8 Characters. Must include a number, a special character, an uppercase and a lowercase letter."
          variant="outlined"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <TextField
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
          }}
          label="Confirm Password"
          variant="outlined"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          className={classes.btn}
          type="submit"
          style={{ width: "95%" }}
          variant="contained"
          disabled={!validateForm()}>
          Change Password
        </Button>
      </form>
    </div>
  );
}
