import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

export default function IhcGraph(props) {
  const [data, setData] = useState([]);
  const barColors = ["#1a2e35", "#385a64", "#BC343E"];
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [ticks, setTicks] = useState([]);

  useEffect(() => {
    const allowed = ["init_weight", "holder_weight", "closer_weight"];

    if (props.data) {
      const filtered = Object.keys(props.data)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = props.data[key];
          return obj;
        }, {});

      var output = Object.entries(filtered).map(([key, value]) => ({
        key,
        value,
      }));

      output.forEach((el) => {
        if (el.key === "init_weight") {
          el.key = " Initializer phase weight";
        }
        if (el.key === "holder_weight") {
          el.key = " Holder phase weight";
        }
        if (el.key === "closer_weight") {
          el.key = " Closer phase weight";
        }
      });

      setData(output);

      const values = [];

      output.forEach((el) => {
        values.push(el.value);
      });

      const min = Math.min(...values);
      const max = Math.max(...values);

      const minYAxis = Math.floor((min - 0.1) * 10) / 10;
      const maxYAxis = Math.ceil((max + 0.1) * 10) / 10;

      setMinValue(minYAxis);
      setMaxValue(maxYAxis);

      const ticks = [minYAxis, maxYAxis];

      let i;
      for (i = minYAxis + 0.1; i < maxYAxis; i += 0.1) {
        ticks.push(parseFloat(i.toFixed(1)));
      }
      setTicks(ticks.sort());
    }
  }, [props.data]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="key" />
          <YAxis
            type="number"
            ticks={ticks ? ticks : null}
            domain={[minValue, maxValue]}
            interval={0}
          />
          <Tooltip />
          <Bar dataKey="value" fill="#1a2e35">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
