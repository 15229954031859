import React from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "90%",
    margin: "auto",
    padding: "1rem",
    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 15%)",
    borderRadius: "5px",
    minHeight: "65vh",
    display: "flex",
  },
  signupForm: {
    width: "90%",
    height: "auto",
    margin: "auto",
  },
  formInput: {
    margin: "0.5rem",
  },
  phoneNumberLine: {
    display: "flex",
    margin: "0.5rem",
    width: "100%",
    justifyContent: "space-between",
  },
  errorMessage: {
    minHeight: "1.5rem",
    padding: "1rem",
    margin: "0.5rem",
    border: "2px solid crimson",
    borderRadius: "5px",
    color: "crimson",
    fontSize: "0.9rem",
  },
  resendMessage: {
    margin: "1rem 0.5rem",
    borderRadius: "5px",
    fontSize: "1rem",
    border: "2px solid green",
    textAlign: "center",
    padding: "1rem 0.25rem",
  },
  succesMessage: {
    height: "3rem",
    padding: "2rem",
    margin: "auto",
    border: "2px solid #216d21",
    borderRadius: "5px",
    color: "#216d21",
    fontSize: "1",
  },
  submitSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0.5rem",
  },
  actionButtons: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-end",
  },
  navigatorLink: {
    color: "#3f51b5",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "0.25rem",
  },
  submitButton: {
    color: "white",
    fontWeight: "600",
    background: "#BC373E",
    margin: "auto 0",
  },
}));

export default function ConfirmSignUp({ user, setFormState }) {
  const classes = useStyles();

  const [formFields, setFormFields] = useState({
    username: user?.username,
    confirmation_code: "",
  });
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState("");
  const [resendStatus, setResendStatus] = useState({ sent: false, to: false });
  const [fetchingData, setFetchingData] = useState(false);

  const onChange = (e) => {
    setFormError("");
    const { id, value } = e.target;
    let obj = { ...formFields };
    obj[id] = value;
    setFormFields(obj);
  };

  const onSignIn = async () => {
    setFetchingData(true);
    let formData = { ...formFields };
    try {
      let confirmedUser = await Auth.confirmSignUp(
        formData.username,
        formData.confirmation_code
      );
      console.log(confirmedUser, "Succesfully confirmed");

      setSuccess(
        "Confirmation succesful. You are being redirected to login page."
      );

      setTimeout(() => {
        setFormState("sign-in");
      }, 2000);
    } catch (err) {
      setFormError(err.message);
    }
  };

  const resendVerificationCode = async () => {
    setFormError(false);
    setResendStatus({ sent: false, to: false });
    setFetchingData(true);
    if (!formFields.username) {
      setFormError("Please enter your username");
      return;
    }

    try {
      let resend = await Auth.resendSignUp(formFields.username);
      setResendStatus({
        sent: true,
        to: resend.CodeDeliveryDetails.Destination,
      });
      setTimeout(() => {
        setResendStatus({ sent: false, to: false });
      }, 5000);
    } catch (err) {
      setFormError(err.message);
    } finally {
      setFetchingData(false);
    }
  };

  return (
    <div className={classes.formContainer}>
      {success && <div className={classes.succesMessage}>{success}</div>}
      {!success && (
        <form autoComplete="no" className={classes.signupForm}>
          <h3 style={{ margin: "1rem 0.5rem" }}>Confirm Sign-up </h3>

          <p style={{ margin: "1rem 0.5rem", lineHeight: "1.2rem" }}>
            Please type in your username and the verification code you have
            received in your mail.{" "}
          </p>

          {resendStatus.sent && (
            <div className={classes.resendMessage}>
              A new verification code has been sent to {resendStatus.to}!{" "}
            </div>
          )}

          <TextField
            fullWidth
            className={classes.formInput}
            variant="outlined"
            label="Username"
            id="username"
            defaultValue={formFields.username}
            InputLabelProps={{ shrink: formFields.username ? true : false }}
            onChange={(e) => {
              onChange(e);
            }}
          />

          <TextField
            fullWidth
            className={classes.formInput}
            variant="outlined"
            label="Verification Code"
            id="confirmation_code"
            type="text"
            onChange={(e) => {
              onChange(e);
            }}
          />
          {formError && <div className={classes.errorMessage}>{formError}</div>}

          <div className={classes.submitSection}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <div style={{ padding: "0.5rem", marginBottom: "1rem" }}>
                  <span
                    className={classes.navigatorLink}
                    onClick={() => {
                      setFormState("sign-in");
                    }}
                  >
                    Go back to home page
                  </span>
                </div>
              </Grid>

              <Grid item className={classes.actionButtons} xs={6} md={8}>
                <Button
                  disabled={fetchingData}
                  variant="contained"
                  className={classes.submitButton}
                  style={{ backgroundColor: "#3f3d56" }}
                  onClick={resendVerificationCode}
                >
                  Resend Code
                </Button>
                <Button
                  disabled={fetchingData}
                  variant="contained"
                  className={classes.submitButton}
                  onClick={onSignIn}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </div>
  );
}
