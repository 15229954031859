import React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  List,
  Divider,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TimelineIcon from "@material-ui/icons/Timeline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";
import SettingsIcon from "@material-ui/icons/Settings";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "80px",
    height: "auto",
    margin: "0 auto",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "white",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 2px 5px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    backgroundColor: "white",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  signout: {
    marginLeft: "auto",
    background:'#1a2e35',
    padding:'0.5rem 1.5rem',
    color:'white',
    '&:hover':{
      color:'#1a2e35'
    }
  },
}));

export default function Nav() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickOverview = () => {
    history.push("/home");
  };
  const handleClickParams = () => {
    history.push("/params");
  };
  const handleClickTraining = () => {
    history.push("/training");
  };
  const handleClickConversionTypes = () => {
    history.push("/conversion-types");
  };
  const handleClickSettings = () => {
    history.push("/settings");
  };
  const handleClickFeedback = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Button variant='outlined' onClick={()=>{localStorage.clear(); sessionStorage.clear(); document.location.reload()}} className={classes.signout} >Sign Out</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon onClick={handleClickOverview} />
              </ListItemIcon>
              <ListItemText primary="Overview" onClick={handleClickOverview} />
            </ListItem>
            <ListItem button onClick={handleClickParams}>
              <ListItemIcon onClick={handleClickParams}>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="IHC Parameter" />
            </ListItem>
            <ListItem button onClick={handleClickTraining}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText primary="Parameter Training" />
            </ListItem>
            <ListItem button onClick={handleClickConversionTypes}>
              <ListItemIcon>
                <HorizontalSplitIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Conversion Types" />
            </ListItem>
            <ListItem button onClick={handleClickSettings}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleClickFeedback}>
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback/Bug Report" />
            </ListItem>
            <Dialog
              open={modalOpen}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Feedback/ Bug report"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  We are very grateful for any feedback, improvement suggestion
                  or bug reports. Please send us an email at &nbsp;
                  <a href="mailto:info@haensel-ams.com?subject=IHC Feedback/ Bug report">
                    info@haensel-ams.com
                  </a>{" "}
                  with a description and potential screenshots. We will try so
                  solve it and shortly come back to you.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleCloseModal}
                  color="primary"
                  autoFocus
                  href="mailto:info@haensel-ams.com?subject=IHC Feedback/ Bug report"
                >
                  Send Email
                </Button>
              </DialogActions>
            </Dialog>
            <ListItem
              button
              component="a"
              target="_blank"
              href="https://docs.google.com/document/d/1_ODYH0wjhsHwZ4hTVdmKWhbbVXXXSpTuas2fhPFTQFE/edit?usp=sharing"
            >
              <ListItemIcon>
                <ImportContactsIcon />
              </ListItemIcon>
              <ListItemText primary="Getting Started" />
            </ListItem>
          </div>
        </List>
      </Drawer>
    </React.Fragment>
  );
}
