import React from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "90%",
    margin: "auto",
    padding: "1rem",
    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 15%)",
    borderRadius: "5px",
    minHeight: "65vh",
    display: "flex",
  },
  signupForm: {
    width: "90%",
    height: "auto",
    margin: "auto",
  },
  formInput: {
    margin: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      flexBasis: '67.5%',
    },
  },
  phoneNumberLine: {
    display: "flex",
    margin: "0.5rem",
    width: "100%",
    justifyContent: "space-between",
  },
  errorMessage: {
    minHeight: "1.5rem",
    padding: "1rem",
    margin: "0.5rem",
    border: "2px solid crimson",
    borderRadius: "5px",
    color: "crimson",
    fontSize: "0.9rem",
  },
  submitSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0.5rem",
  },
  navigatorLink: {
    color: "#3f51b5",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
  submitButton: {
    color: "white",
    fontWeight: "600",
    background: "#BC373E",
    width: '100%'
  },
  phoneNumberBox:{
    [theme.breakpoints.down("sm")]: {
      flexBasis: '67.5%',
    },
    flexBasis: '77.5%',
  }
}));

export default function SignUpForm({ setFormState }) {
  const classes = useStyles();

  const [formFields, setFormFields] = useState({
    username: null,
    password: null,
    attributes: {
      given_name: null,
      family_name: null,
      address: null,
      email: null,
    },
  });
  const [countryCode, setCountryCode] = useState(49);
  const [phoneNumber, setPhoneNumber] = useState(0)
  const [formError, setFormError] = useState("");

  const onChange = (e) => {
    setFormError("");
    const { id, value } = e.target;
    let obj = { ...formFields };
    if (["username", "password"].includes(id)) obj[id] = value;
    else if (id === "country_code") {
      setCountryCode(value);
    } else if (id === "phone_number") {
      setPhoneNumber(value);
    }else {
      obj.attributes[id] = value;
    }
    setFormFields(obj);
  };

  const checkForEmptyFields = () => {
    for(let [key,value] of Object.entries(formFields)){
      if(key === 'attributes'){
        for(let [k,val] of Object.entries(formFields.attributes)){
          if(!val){
            setFormError(`Please fill in all the fields`)
            return false
          } 
        }
      }else{
        if(value === null){
          setFormError(`Please fill in all the fields`)
          return false
        } 
      }
    }
    return true
  }

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const check = re.test(String(formFields.attributes.email).toLowerCase())
    if(!check){
      setFormError('Please enter a valid email address')
      return false
    }
    return true;
  }
  const validateBeforeSubmit = () => {
    setFormError('')
    let validEmail = validateEmail()
    let noEmptyFields = checkForEmptyFields()
    return noEmptyFields&&validEmail
  }

  const onSignup = async () => {

    let validated = validateBeforeSubmit()
    if(!validated) return false

    let formData = { ...formFields };
    formData.attributes.phone_number = '+'+countryCode+phoneNumber;
    
    try {
      let user = await Auth.signUp(formData);
      Hub.dispatch("UI Auth", {
        event: "AuthStateChange",
        message: "confirmSignup",
        data: user,
      });
    } catch (err) {
      setFormError(err.message);
    }
  };

  return (
    <div className={classes.formContainer}>
      <form autoComplete="no" className={classes.signupForm}>
        <h3 style={{ margin: "2rem 0.5rem" }}>Create a New Account </h3>

        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Username"
          id="username"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="First Name"
          id="given_name"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Last Name"
          id="family_name"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Email"
          id="email"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <div className={classes.phoneNumberLine}>
          <TextField
            className={classes.formInput}
            variant="outlined"
            label="Country Code"
            defaultValue='49'
            id="country_code"
            type='number'
            onChange={(e) => {
              onChange(e);
            }}
            style={{ flexBasis: "20%", margin: "0" }}
          />
          <TextField
            className={classes.phoneNumberBox}
            variant="outlined"
            label="Phone number"
            id="phone_number"
            onChange={(e) => {
              onChange(e);
            }}
          />
        </div>
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Address"
          id="address"
          onChange={(e) => {
            onChange(e);
          }}
        />
        <TextField
          fullWidth
          className={classes.formInput}
          variant="outlined"
          label="Password"
          id="password"
          type="password"
          helperText='Min 8 Characters. Must include a number, a special character, an uppercase and a lowercase letter.'
          onChange={(e) => {
            onChange(e);
          }}
        />
        {formError && <div className={classes.errorMessage}>{formError}</div>}

        <div className={classes.submitSection}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <div style={{ padding: "0.5rem", marginBottom: '1rem' }}>
                Already have an account?
                <span
                  className={classes.navigatorLink}
                  onClick={() => {
                    setFormState("sign-in");
                  }}
                >
                  Sign in
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={onSignup}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
}
