import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Paper, Grid, Box } from "@material-ui/core";

import Select from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import Nav from "../components/MainNav";

import InitializerGraph from "../components/InitializerGraph";
import CloserGraph from "../components/CloserGraph";
import IhcGraph from "../components/IhcGraph";
import ChannelsChart from "../components/ChannelsChart";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: "100px",
    height: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  appbar: {
    position: "relative",
    top: "-10px",
    width: "97%",
    margin: "0 auto",
  },
  appbarTitle: { color: "#ffff" },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paddedHeading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  animatedIcon: {
    height: "80px",
    width: "auto",
  },
  barHeading: {
    paddingBottom: theme.spacing(4),
    marginTop: "3rem",
  },
  paperBar: {
    padding: theme.spacing(7),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  dateWrapper: {
    display: "flex",
    alignItems: "center",
  },
  csvLink: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "borderBox",
    transition: ` background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    fontWeight: "500",
    lineHeight: " 1.75",
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: `rgba(0, 0, 0, 0.04)`,
    },
  },
  titleInfoWrapper: {
    display: "flex",
  },
}));

const IHCparams = () => {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [restructuredData, setRestructuredData] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const startDateString = moment().format("YYYY-MM-DD");
      const endDateString = moment().subtract(30, "days").format("YYYY-MM-DD");
      setUserId(user.attributes.sub);

      const apiName = "ApiGatewayRestApi";
      const path = "/params";
      const myInit = {
        response: true,
        queryStringParameters: {
          userId: user.attributes.sub,
          startDate: endDateString,
          endDate: startDateString,
        },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          setData(response.data[0]);
          setTableData(response.data[1]);
          let convtypesArr = [];
          response.data[0].forEach((obj) => {
            convtypesArr.push({
              value: obj[Object.keys(obj)[0]],
              label: obj[Object.keys(obj)[0]],
            });
          });
          setOptions(convtypesArr);
        })
        .catch((error) => {
          console.log(error.response);
        });
    });
  }, []);

  const handleChange = (value) => {
    let filterValue = value.value;

    let filterData = data.filter(function (el) {
      return el.Conv_Type_ID === filterValue;
    });

    let roundedFilteredData = JSON.parse(
      filterData[0].IHC_Parameter,
      (key, value) =>
        typeof value === "number"
          ? Math.round((value + Number.EPSILON) * 100) / 100
          : value
    );
    setFilteredData(roundedFilteredData);
    setGraphData(roundedFilteredData);
  };


  const setGraphData = (roundedFilteredData) => {
    if (roundedFilteredData) {
      const data = roundedFilteredData;
      const keysInit = Object.keys(
        data.channel_redistribution_weights.initializer
      );
      const keysHolder = Object.keys(
        data.channel_redistribution_weights.holder
      );
      const keysCloser = Object.keys(
        data.channel_redistribution_weights.closer
      );

      let allKeys = keysInit.concat(keysHolder, keysCloser);
      allKeys = allKeys.filter((item, index) => {
        return allKeys.indexOf(item) === index;
      });

      const arr = [];
      for (const key of allKeys) {
        var elem = new Object();
        elem.channel = key;
        arr.push(elem);
      }
      arr.forEach((obj) => {
        obj["initializer weight"] =
          data.channel_redistribution_weights.initializer[obj.channel];
        obj["holder weight"] =
          data.channel_redistribution_weights.holder[obj.channel];
        obj["closer weight"] =
          data.channel_redistribution_weights.closer[obj.channel];
      });
      setRestructuredData(arr);
    }
  };

  return (
    <div className={classes.root}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className={classes.paddedHeading}>
                IHC parameters
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.paddedHeading}>
                  Select a conversion type to populate the graphs:
                </Typography>
                <div style={{ width: "50%" }}>
                  <Select options={options} onChange={handleChange} />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper className={classes.paper}>
                  <div className={classes.titleInfoWrapper}>
                    <Typography variant="h6">Initializer membership</Typography>
                    <Tooltip
                      placement="top"
                      title="The initializer function shows the Initializer membership of a subsequent session, based on the hour difference to its prior sessions = hours since last session. It is derived from in inverse quantile function of inter-session times seen in the training data.">
                      <InfoIcon
                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>

                  {filteredData ? (
                    <InitializerGraph data={filteredData} />
                  ) : (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "#e5e5e5" }}
                      className={classes.paddedHeading}>
                      No data to display
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper className={classes.paper}>
                  <div className={classes.titleInfoWrapper}>
                    <Typography variant="h6">Closer membership</Typography>
                    <Tooltip
                      placement="top"
                      title="The closer membership function is in reverse time direction and shows the closer membership of a closer valid engagement session in relation to the hours to the conversion, 0 is the time of conversions. It is derived from the quantile function of closer eligible session to the respective conversion events seen in the training data.">
                      <InfoIcon
                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>

                  {filteredData ? (
                    <CloserGraph data={filteredData} />
                  ) : (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "#e5e5e5" }}
                      className={classes.paddedHeading}>
                      No data to display
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Paper className={classes.paper}>
                  <div className={classes.titleInfoWrapper}>
                    <Typography variant="h6">IHC phase weights</Typography>
                    <Tooltip
                      placement="top"
                      title="The I-H-C interaction phases weights show the relative importance of the phases to each other. The values are derived from the distribution of session attribution values of the respective phases. The idea is, the faster an interaction phase is usually covered, the less important it is. In other words, the longer an interaction phase spreads over time within the customer journey, i.e. the longer the customers are usually within a phase and not transitioning to the next level - the more important this phase is in terms of marketing focus and hence a higher weight.">
                      <InfoIcon
                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>

                  {filteredData ? (
                    <IhcGraph data={filteredData} />
                  ) : (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "#e5e5e5" }}
                      className={classes.paddedHeading}>
                      No data to display
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Paper className={classes.paper}>
                  <Box display="flex" justifyContent="space-between">
                    <div className={classes.titleInfoWrapper}>
                      <Typography variant="h6">IHC channel weights</Typography>
                      <Tooltip
                        placement="top"
                        title="The channel weights per I-H-C phase are derived from the marginal added attribution value of the channels within the respective interaction phase, scaled to values of the other channels and adjusted for the statistical significance in terms of observed sample size.">
                        <InfoIcon
                          style={{ marginLeft: "1rem", cursor: "pointer" }}
                        />
                      </Tooltip>
                    </div>

                    <CSVLink
                      className={classes.csvLink}
                      data={
                        restructuredData
                          ? restructuredData
                          : [{ data: "empty" }]
                      }
                      filename="IHC_channel_weights.csv">
                      Download CSV
                    </CSVLink>
                  </Box>
                  {filteredData ? (
                    <ChannelsChart
                      data={restructuredData ? restructuredData : null}
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "#e5e5e5" }}
                      className={classes.paddedHeading}>
                      No data to display
                    </Typography>
                  )}
                </Paper>
              </Grid>

              
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default IHCparams;
