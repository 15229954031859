import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    display: "inline-block",
    width: "60%",
    float: "right",
    marginBottom: "2rem",
  },
  table: {
    width: "100%",
    border: "1px solid #dedede",
    borderRadius: "5px",
    padding: "0.5rem",
    paddingTop: '0'
  },
  tableTitle: {
    margin: "0 0 0.5rem 0",
  },
  tableHeaders: {
    textAlign: "left",
    width: '100%'
  },
  tableBody:{
    maxHeight: '4rem',
    width:'100%',
    display:'inline-block',
    overflowY: 'auto'
  },
  tableLine: {
    width: "100%",
    height: "1.75rem",
  },
  headerCell: {
    width: "32.5%",
    display: 'inline-block',
    fontWeight: "600",
    fontSize: "0.8rem",
    borderBottom: '1px solid silver'

  },
  tableCell: {
    width: "33.3%",
    display: 'inline-block',
    fontSize: "0.8rem",
    borderBottom: '1px solid #dedede'

  },
}));

function RecentTrainingsTable({ recentTrainings }) {
  const classes = useStyles();

  return (
    <>
      {recentTrainings.length > 0 && (
        <div className={classes.container}>
          <h4 className={classes.tableTitle}>Active Trainings</h4>
          <div className={classes.table}>
            <div className={classes.tableHeaders}>
                <p className={classes.headerCell}>Conversion Type</p>
                <p className={classes.headerCell}>Uploaded At</p>
                <p className={classes.headerCell}>Status</p>
            </div>
            <div className={classes.tableBody}>
              {recentTrainings.map((training, index) => {
                return (
                    <div key={index} className={classes.tableLine}>
                      <span className={classes.tableCell}>{training.Conv_Type_ID}</span>
                      <span className={classes.tableCell}>
                        {training.Request_Timestamp.split('.')[0]} (UTC)
                      </span>
                      <span className={classes.tableCell}>{training.Request_Status}</span>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RecentTrainingsTable;
